import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./ButtonLoader.module.scss";

const ButtonLoader = (props) => {
  const { className, size, thick } = props;
  return (
    <div
      className={classNames(
        styles.loader,
        className,
        styles[`size-${size}`],
        styles[`thick-${thick}`]
      )}
    />
  );
};

export default ButtonLoader;

ButtonLoader.propTypes = {
  className: PropTypes.any,
  size: PropTypes.oneOf([12, 14, 16, 18, 20, 22, 24, 26]),
  thick: PropTypes.oneOf([2, 3, 4, 5, 6]),
};

ButtonLoader.defaultProps = {
  className: null,
  size: 16,
  thick: 2,
};
