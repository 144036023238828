/* eslint-disable react/forbid-elements */
import { useTheme } from "@context/theme";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import ButtonLoader from "../ButtonLoader";
import styles from "./Button.module.scss";

export type ButtonType =
  | "primary"
  | "secondary"
  | "text"
  | "danger"
  | "green"
  | "orange"
  | "greenLight"
  | "blueLight"
  | "redLight"
  | "accent1"
  | "border"
  | "borderDark"
  | "blueBorder"
  | "borderGreen"
  | "borderRed"
  | "borderOrange"
  | "borderBlue";

export type ButtonSize = "sm" | "md" | "lg";

type Props = PropsWithChildren<{
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: ButtonType;
  showResponsiveButton?: boolean;
  size?: ButtonSize;
  /**
   * @deprecated Use children instead.
   */
  buttonText?: string;
  /**
   * @deprecated Use children instead.
   */
  buttonTextClassName?: string;
}>;

const Button = (props: Props) => {
  const { isTablet } = useTheme();
  const size = props.size || "lg";

  return (
    <button
      className={twMerge(
        classNames(
          styles[props.type || "primary"],
          "relative",
          {
            "btn-typo-16":
              (!props.showResponsiveButton || isTablet) && size === "lg",
            "btn-typo-12":
              (props.showResponsiveButton && !isTablet) || size === "sm",
            "btn-typo-14": size === "md",
            [styles.responsiveButton]: props.showResponsiveButton,
            [styles.button]: !props.showResponsiveButton,
          },
          props.className
        )
      )}
      type="button"
      onClick={props.onClick}
      disabled={props.isLoading || props.isDisabled}
    >
      {props.buttonText ? (
        <div
          className={classNames("text-center", props.buttonTextClassName, {
            "opacity-0": props.isLoading,
          })}
        >
          {props.buttonText}
        </div>
      ) : (
        <span className="flex items-center justify-center">
          {props.children}
        </span>
      )}
      {props.isLoading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <ButtonLoader />
        </div>
      )}
    </button>
  );
};

export default Button;
