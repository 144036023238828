import Button from "@components/Common/Button";
import { useAgency } from "@context/agency";
import AuthServices from "@services/AuthServices";
import Head from "next/head";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const { logoUrl, agencyId, agencyName } = useAgency();
  const [isLoading, setIsLoading] = useState(false);

  async function onLogin() {
    try {
      setIsLoading(true);
      await AuthServices.logInWithRedirect(logoUrl, "", agencyId);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Fragment>
      <Head>
        <title>{"Login to HUB | TGG Accounting"}</title>
      </Head>
      <div className="w-screen h-screen flex items-center justify-center login-container relative">
        <img
          src="/assets/images/login-cover.png"
          alt="login-cover"
          className="absolute h-screen w-full object-cover"
        />
        <div className="p-10 rounded1 bottom-high flex flex-col items-center w-11/12 max-w-lg bg-primary1 z-10">
          <img
            src={logoUrl || "/assets/images/logo2.webp"}
            alt="tgg-logo"
            className="w-24 mb-2"
          />
          {!!agencyName && (
            <div className="text-lg mb-4 text-white">{agencyName}</div>
          )}
          <Button
            buttonText={t("common.login")}
            type="secondary"
            onClick={onLogin}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
